import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SelectedBlogPostMobile from "./components/SelectedBlogPostMobile";
import ReactGA from "react-ga";
import "./fonts.css";

ReactGA.initialize("G-HQE8BP66DS");

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/post/:id" element={<SelectedBlogPostMobile />} />
      </Routes>
    </Router>
  );
};

export default App;
