/*
Template = 

{
    id: x,
    title: "",
    date: "",
    section: "",
    content: ``,
    externalUrl: "",
  },
*/

const blogPosts = [
  {
    id: 1,
    title: "About me",
    date: "",
    section: "aboutMe",
    content: `
    {{img:/img/anon-pic.png;style:width:30%}}
    
    Hey I'm Wira,

    I’m currently building <a href="https://www.orca.so/" target="_blank">Orca</a> to be the best DEX in all of crypto. I previously studied Information Science and Philosophy at Cornell, where I also led governance for Cornell Blockchain. Most of my time is spent designing systems that are not only aesthetically sound but also highly efficient and impactful for society. The world runs on beautiful systems. I’m drawn to web3 because of its great potential to create a more equitable, self-sovereign society.
  
    You can contact me at <a href="mailto:prawira@pikanto.xyz">prawira@pikanto.xyz</a> or on <a href="https://t.me/prawirapikanto" target="_blank">Telegram</a>.
    `,
  },
  // {
  //   id: 2,
  //   title: "Constant Distress Theory",
  //   date: "January 2022",
  //   section: "blogpost",
  //   content: ``,
  // },
  // {
  //   id: 4,
  //   title: "Duality of Life",
  //   date: "January 2022",
  //   section: "blogpost",
  //   content: ``,
  // },
  {
    id: 9,
    title: "The Chase Against Time",
    date: "January 2022",
    section: "blogpost",
    gated: true,
    content: ``,
  },
  {
    id: 7,
    title: "Futurism",
    date: "",
    section: "paintings",
    content: `
    {{img:/img/futurism.jpg;style:width:100%}}
    
    2018 / 90 x 160 cm / acrylic on canvas`,
  },
  {
    id: 8,
    title: "Muka Dua (Two Face)",
    date: "",
    section: "paintings",
    content: `
    {{img:/img/mukaDua.png;style:width:100%}}
    
    2022 / 90 x 160 cm / acrylic on canvas 

    In today's society, individuals often find themselves subjected to the constant judgment of others. This phenomenon stems from a human tendency to label others, often disregarding their underlying circumstances. As a result, many people feel compelled to wear masks to gain acceptance and avoid rejection, since divergence from the norm is frequently perceived as a flaw.

    While putting on a facade might garner the approval of those around you, it simultaneously suppresses your authentic self. Over time, you may lose sight of your true identity, experiencing inner turmoil as you waver between your carefully crafted persona and your genuine self. Without a strong sense of authenticity, life can become aimless, and true fulfillment elusive. Talents and passions may become obscured, potentially reaching a point of no return. Ultimately, there's a risk of surrounding yourself with insincere individuals who value only the superficial aspects of your identity, rather than appreciating the true essence of who you are and becoming great.

`,
  },
  {
    id: 5,
    title: "Picking the Right Battle",
    date: "May 2022",
    section: "blogpost",
    content: ``,
    externalUrl:
      "https://medium.com/@pikanto/picking-the-right-battle-a9c2d581160",
  },
  /*{
    id: 10,
    title: "Web3 Citizen",
    date: "May 2023",
    section: "blogpost",
    content: ``,
    externalUrl:
      "https://medium.com/@pikanto/design-framework-2-web3-citizen-229283e33e28",
  },*/
  /*{
  {
    id: 11,
    title: "NFT Utility Standard",
    date: "May 2023",
    section: "blogpost",
    content: ``,
    externalUrl:
      "https://medium.com/@pikanto/nft-utility-standard-c2030413cf6a",
  },*/
  {
    id: 13,
    title: "Centralizing DAOs",
    date: "May 2023",
    section: "blogpost",
    content: ``,
    externalUrl:
      "https://medium.com/@pikanto/on-decentralized-governance-degov-9539b50777f4",
  },
  {
    id: 12,
    title: "Reality First, Digital Second",
    date: "May 2023",
    section: "blogpost",
    content: ``,
    externalUrl:
      "https://medium.com/@pikanto/reality-first-digital-second-257d4e39ff58",
  },
  {
    id: 102,
    title: "OSIRIS: The Content Evaluation Protocol WP",
    date: "Dec 2023",
    section: "others",
    content: `<a href="https://docs.google.com/document/d/1QxJE5CNVJ8C3_3-3Hri_rkOZ44ZcZKUSMLQNoCjnyLg/edit?usp=sharing"> Link</a>. Email me for access`,
  },
  {
    id: 103,
    title: "Nylon: The Interest Graph Protocol WP",
    date: "Feb 2023",
    section: "others",
    content: `<a href="https://docs.google.com/document/d/1Hf9ZKaz9U-xoSUU8HB_cLY-etHQHs3qjGJJywvcbeQc/edit?usp=sharing"> Link</a>. Email me for access`,
  },
  {
    id: 104,
    title: "ERC-721 Utility Standard",
    date: "May 2023",
    section: "others",
    content: ``,
    externalUrl: "https://github.com/wirwo/nft-utility-standard-v1",
  },
  {
    id: 16,
    title: "Cornell Blockchain",
    date: "May 2022",
    section: "designs",
    content: ``,
    externalUrl: "https://www.cornellblockchain.org",
  },
  {
    id: 17,
    title: "Nylon: The Interest Graph Protocol",
    section: "designs",
    gated: true,
    content: `
    {{img:/img/Nylon/NylonBanner.png}}
    
    Desc: Designing a decentralized rewards platform on <a href= https://optimism.io/ target="_blank">Optimism</a>.
    Start/End Date: May - December 2023
    Team: 2 People 
    Tools: Figma



    Nylon is still in the works, to see ongoing mobile app design, please contact me
    `,
  },
  {
    id: 19,
    title: "Overmind: Solve-to-Earn Platform",
    section: "designs",
    gated: true,
    content: `
    {{img:/img/Overmind/OvermindBanner.png}}
    
    Desc: Designing a solve-to-earn platform on <a href= https://aptos.network/ target="_blank">Aptos</a>.
    Start/End Date: May - December 2022
    Team: 2 People 
    Tools: Figma



    <h3>Overview</h3>
    In late 2022, Aptos, a Layer 1 project that emerged from the Facebook Libra team, launched. Libra's downfall a year prior led to the inception of a groundbreaking smart contract language called Move. Compared to prevailing languages like Solidity and Rust, Move offers an enhanced safety proposition, positioning it as a potential game-changer in smart contract development.

    However, the uniqueness of Move presents challenges for Aptos and other budding L1 chains. The entirely new language creates a learning curve, making it challenging to attract developers from established ecosystems. Overmind's goal is to encourage blockchain developers to learn Move and enter the Aptos’ developer ecosystem.

    To achieve this, we create technical puzzles in Move for developers to solve. Successful solutions, if submitted within the stipulated period and ranked among the top submissions, are rewarded with money and a unique NFT. These Overmind soulbound NFTs act as a testament to a developer's skill set. One puzzle example involves the creation of fractionalized NFTs on Aptos.

    A potential concern? Developers creating multiple accounts to exploit the rewards. Anticipating this, we developed a creative solution that requires developers to connect their Github account, and the Github need to be created before Overmind went live. We also embedded a plagiarism checker.

    The flow to submit puzzles are as follows.
    <ul>
    <li>Developer signs in by connecting her Github account</li>
    <li>Goes to her profile page and connects her Aptos wallet</li>
    <li>Goes to the puzzle page and clone the Github repo</li>
    <li>Push the solution to the cloned Github repo</li>
    <li>Click Submit</li>
    <li>If she places in the top X, $Y and puzzle NFT will be sent to her wallet upon review</li>
    </ul>

    For this project, I worked with one other designer to build it from scratch. Below are the highlights of the platform we designed.


    
    <h3>Highlights</h3>
    {{img:/img/Overmind/Home.png}}



    <h3>1. Color Theme & Typography</h3>
    Aiming for a "hacker" feel, appropriate for our developer audience, we took inspiration from platforms like Paradigm’s CTF, CodeArena, and ZKHack. Our choice of font is Source Code Pro, complemented by the color palette displayed below.

    {{img:/img/Overmind/ColorTheme.png}}



    <h3>2. Navigation Bar</h3>
    The navigation bar has 3 components, comprising the Leaderboard (ranking active participants), Puzzles (a list of puzzles on the platform), and user profile.

    {{img:/img/Overmind/Navbar.png}}



    <h3>3. Sign In</h3>
    {{img:/img/Overmind/GithubSignIn.png}}



    <h3>4. Leaderboard</h3>
    The Leaderboard lists participants ranked by their earnings on Overmind.

    {{img:/img/Overmind/Leaderboard.png}}


    
    <h3>5. Puzzles</h3>
    The Puzzles page lists both active and inactive challenges, each tagged with its difficulty. They are categorized as Common, Rare, and Legendary in ascending order of complexity.

    {{img:/img/Overmind/PuzzleList.png}}


    
    <h3>6. Profile</h3>
    Here, users can view their solved puzzles, edit personal details, and link their wallet — a critical step to ensure the transfer of rewards and puzzle NFT.

    {{img:/img/Overmind/UserProfile.png}}



    <h3>7. Puzzle Page</h3>
    This page displays the puzzle details, expiry, and rewards. The green components guide developers to clone the Github repo and submit their solutions for review.

    {{img:/img/Overmind/PuzzlePage.png}}






    `,
  },
  {
    id: 22,
    title: "Chronos: The Time Protocol",
    section: "designs",
    gated: true,
    content: `
    {{img:/img/Chronos/ChronosBanner.png}}
    
    Desc: Designing a decentralized talent marketplace on <a href = "https://cosmos.network/" target="_blank"> Cosmos </a>
    Start Date: March - May 2022
    Team: 2 People 
    Tools: Figma
    


    <h3>The Problem</h3>
    The mainstream centralized talent marketplaces have noticeable drawbacks:
    <ul>
    <li> High Fees: Platforms such as Upwork impose significant deductions, charging freelancers up to 10% and clients an additional 5% on payments. </li>
    <li> Extended Payment Cycles: Freelancers engaged in hourly contracts face a wait time of up to 10 days to access their earnings for a week’s worth of work. </li>
    </ul>
    

    
    <h3>Overview</h3>
    Chronos aims to revolutionize the talent marketplace. By leveraging an open protocol and using smart contracts, we've significantly reduced fees and introduced an instantaneous billing cycle. Post extensive surveys with over 100 blockchain companies and DAOs, we found a daring need of finding good quality developers. Therefore, the first iteration of our platform is tailored to this niche.

    We've created a two-sided marketplace, facilitating connections between DAOs and Contributors. In a nutshell, the flow of the platform is as follows: when a contract is established, a DAO deposits the total payment into an escrow. This escrow then disburses payments to the contributor on an hourly basis, either until the contract reaches its end or is terminated by a mutual agreement.

    For example: Alex, a developer, agrees to a contract where his time is valued at $25/hour for a 4-week project at 40 hours a week. The DAO deposits 4000 USDC into the escrow, which then releases 25 USDC to Alex for every hour he works.

    As a founding designer, I collaborated closely with another product designer. Together, we laid the foundational design elements for the platform. This portfolio will spotlight crucial elements that embody the core of our design efforts.
    


    <h3> Highlights </h3>
    <h3> 1. Color Theme </h3>
    
    {{img:/img/Chronos/Color.png}}



    <h3> 2. Sign-up Process </h3>
    Our landing page welcomes both Contributors and DAOs. Since this is a web3 platform, the sign in/sign up process involves connecting a wallet and the system will detect if a user have already signed up. If a user have already made an account, he would enter the dashboard, but if not, the DAO or the contributor needs to follow the sign up process.

    {{img:/img/Chronos/LandingConnect.png}}

    Using the research data we collected from other talent marketplaces, we designed a simple sign up process for contributors that begins with contributors identifying their prime skills, followed by their availability and preferred hourly rates. At last, contributors specify their desired token for compensation.

    {{img:/img/Chronos/SignUp.png}}



    <h3> 3. Talent Point of View </h3>
    Before diving into contributors’ dashboard, lets talk about the navbar. The navigation bar has four important components: 
    <ul>
    <li>Find Work: To browse job opportunities.</li>
    <li>My Applications: Listing previously submitted job applications.</li>
    <li>My Contracts: Viewing active and inactive contracts.</li>
    <li>Profile: An overview of the talent’s credentials.</li>
    </ul>
    
    {{img:/img/Chronos/Navbar.png}}
    

    
    <h3> A. Find Work </h3>
    After account creation, contributors access a dashboard filled with open opportunities. Each job component has the job's details, from its description, duration, time commitment, to estimated compensation. Talents can also use the advanced filter to streamline searches, facilitating quick job matches. Every job listing is just a click away from a detailed view, where the talents can start the application process.
    
    {{img:/img/Chronos/FindWork.png}}
    

    
    <h3> B. My Applications </h3>
    <ul>
    The applications page is divided into three sections: 
    <li>Offers: Lists job invitations by DAOs, awaiting the talent’s decision to accept or decline the offer. </li>
    <li> Pending: Lists all the jobs that the contributor has applied to and awaiting for the DAOs’ responses. Here the contributors can withdraw the application if they’re no longer interested. </li>
    <li> Declined/Withdrawn: An archive of all job applications that have been withdrawn or declined by the DAOs.</li>
    </ul>
    
    {{img:/img/Chronos/MyApp.png}}
    


    <h3> C. My Contracts </h3>
    The contracts page lists all the jobs that a contributor has accepted and committed to. The page has two sections: “Active” and “Inactive". In the “Active” page, the contributors can claim the funds anytime since they’re getting paid automatically by the hour. In such situation, the fund will get transferred to the contributor’s wallet address. Additionally, the contributors can also cancel the contract if there is any dispute. The “Inactive” page just lists all the jobs that the contributor has completed.
    
    {{img:/img/Chronos/MyContracts.png}}
    


    <h3> D. Talent Profile </h3>
    The profile page presents a holistic view of the contributors’ professional journey, showcasing skills, badges, work history, and feedback from the DAOs they have worked with.
    
    {{img:/img/Chronos/ContributorProfile.png}}
    


    <h3> 4. DAO Point of View </h3>
    The navbar for the DAO mainly consists of Dashboard and DAO profile.

    {{img:/img/Chronos/DAONav.png}}


    
    <h3> A. Dashboard </h3>
    The dashboard is divided into two sections. The top section shows three metrics that might be useful for the DAO admin: total amount of money locked, the number of jobs listed, and the total amount of money paid. The bottom section displays the jobs that are active/listed and closed. Additionally, the DAO can also post new jobs by clicking the “Post New Job” button.

    {{img:/img/Chronos/DashboardHome.png}}

    When a job component is clicked, it navigates to the page below. The top section displays the details of the job as well as two buttons that allows the DAO admin to view the live job listing or close it. The bottom section displays all the contracts associated with the job and has three sections: Active Contracts, Offers Sent, and Applicants to Review.

    The “Active Contract” section displays all the contributors that are currently in contract. Each contract components have the name of the contributor, start and end date, time commitment, the $ amount escrowed, and the $ amount that has been released to the contributor. After the contract has concluded, the DAO can leave a review and feedback to the contributor. The DAO also has the option the end the contract. In such situation, both parties will go the dispute process and at the end, the DAO can claim the unreleased balance in the escrow.

    {{img:/img/Chronos/ActiveContracts.png}}

    The “Applicants to Review” section lists all the contributors that have applied for the job. The DAO can either approve or reject the applications. If the DAO approves, an offer would be sent to the applicants.

    {{img:/img/Chronos/ApplicantsToReview.png}}

    The “Offers Sent” section lists all the applicants that the DAO has sent offers to. At this stage, the DAO is waiting for the applicants to accept or decline the offer.

    {{img:/img/Chronos/OffersSent.png}}



    <h3> B. DAO Profile </h3>
    The left section of the DAO profile page lists the active job listings and the reviews that the DAO received from the past contributors that it has worked with. The right section displays the DAO’s metrics that might be useful for contributors from the total amount paid to contributors, total money locked in the escrow account, amount of jobs completed and active.

    {{img:/img/Chronos/DAOProfile.png}}


    `,
  },
  {
    id: 18,
    title: "Elrond: All-in-One DAO Platform",
    section: "designs",
    content: `
    {{img:/img/Elrond/ElrondBanner.png}}
    
    Desc: Designing an all-in-one DAO platform on <a href= https://ethereum.org/ target="_blank">Ethereum</a>
    Start/End Date: November 2021 - January 2022 
    Team: 2 People 
    Tools: Figma
    <i> (You can dive deeper into the conceptual foundation of DAOs by exploring my article titled <a href =https://medium.com/@pikanto/on-decentralized-governance-degov-9539b50777f4 target="_blank"> Reputable Governance</a>) </i>
    


    <h3> Research & Ideation </h3>
    In 2021, the decentralized autonomous organization (DAO) concept was in its nascent stage. A plethora of platforms mushroomed, each trying to cater to a fragment of the functionalities essential for effective DAO management. For example: 
    <ul>
    <li>Snapshot: The most popular voting platform for DAOs </li>
    <li>Discourse: Hosts topic discussions that turns into proposals that is voted in snapshot </li>
    <li>Discord: Informal community chats </li>
    <li>Gnosis Safe: The most popular multisig platform to manage DAO treasury </li>
    <li>SourceCred and Coordinape: Early experiments on contributor reputation </li>
    <li>Gitcoin and Dework: Platform to issue bounties </li>
    <li>Juice Box: To fundraise projects, like gofundme </li>
    </ul>

    While this plethora of platforms gave DAOs versatility, it also presented a learning curve, with members having to juggle between multiple platforms to manage their daily operations. The guiding philosophy behind Elrond was crystal clear: If DAOs are to become a formidable replacement for traditional LLCs, their management tools should be intuitive, streamlined, and seamlessly integrated.

    To create a platform that aligned with the real-world needs of DAO members, we embarked on a rigorous research process, interviewing DAO contributors and diving deep into the daily tasks that occupied most of their time. Below are the rankings:
    <ul>
    <li>Discussions</li>
    <li>DAO Tasks</li>
    <li>Voting on Proposals</li>
    <li>Treasury Management</li>
    <li>Recruitment</li>
    </ul>

    Collating these insights, we began to prototype an all-encompassing platform, merging fragmented tasks into one unified experience. Below are the highlights of the platform we designed.



    <h3>1. Layout</h3>
    {{img:/img/Elrond/StartingPoint.png}}

    There are three important components that appears in all pages: Side NavBar, DAO Menu, Top Bar. The layout similarities to Discord is intentional because it improves mapping and lowers down the learning barriers for users.

    The <strong> Top Bar </strong> is divided into two sections:
    <ul>
    <li>Section 1: The left side houses the standard back and forward navigation buttons. The right side shows your current path, indicating your current location within the platform.</li>
    <li>Section 2: To the left is a universal search bar for the entire DAO. In the middle, we have an  inbox icon, highlighting notifications from the DAOs you're affiliated with. On the right, is a button to your profile page.</li>
    </ul>

    The <strong> Side NavBar </strong> consists of three sections:
    <ul>
    <li>Section 1: Contains default buttons that allow users to navigate to home, notifications, wallet, and tasks.</li>
    <li>Section 2: Lists all the DAOs you're a member of. At the section's end, a "+" button lets you explore and join moire DAOs in the marketplace.</li>
    <li>Section 3: Features the Dev Store button, where users can browse and install integrations that enhance the DAO management experience</li>
    </ul>

    The <strong> DAO Menu </strong> is segmented into five sections:\
    <ul>
    <li>Section 1: Lists the key pages consisting of the Feed, Contributors, Tasks, Discussions, Voting Booth, among others. This is customizable meaning the DAO admins can create, edit, and delete the pages listed here.</li>
    <li>Section 2: Channels that act as communication hubs for contributors, similar Slack channels. These can either be public or set to private, requiring an invite or token for access.</li>
    <li>Section 3: Voice Channels serve as virtual meeting spaces where contributors can converse in real-time. These, too, can be public or restricted to invite-only or token access.</li>
    <li>Section 4: Pods mirror departments in the traditional corporate structure. They are specialized teams within a DAO that collaborate to realize the DAO's objectives. For instance, Software Engineering, Design, and Finance</li>
    <li>Section 5: Direct messages or group chats between contributors.</li>
    </ul>



    <h3>2. Home Feed</h3>
    The feed serves two main purposes. First, displaying posts from people you follow, reminiscent of Twitter. Second, showcasing information from the DAOs that a contributor is a part of. This may include mentions, announcements, direct messages, or task progress.

    To the right, we have a sidebar with several buttons and modules. Users can explore and join more DAOs, discover open bounties to enhance their reputation, and install integrations to boost work efficiency by browsing the dev shop. Additionally, "My Tasks" and "Calendar" modules ensure you stay organized, while "Popular Topics" and "Who to Follow" enhance your feed experience.

    {{img:/img/Elrond/Feed.png}}



    <h3>3. App Store</h3>
    Here, contributors can explore available integrations. Think of integrations here as akin to VS Code's—companies like Asana, Atlassian, Figma, and Google can develop apps on Elrond to elevate the DAO management experience.

    To the right, modules such as "Most Popular Integrations" aid contributors in discovering and installing these integrations.

    Clicking on an app directs contributors to the integration’s detailed page, allowing them to install it. For example, integrating Google Calendar means contributors can sync all the important events in Elrond with Google Calendar!

    {{img:/img/Elrond/DevShop.png}}



    <h3>4. DAO Home</h3>
    When selecting a DAO from the side navbar, you're brought to its homepage. As an example, consider the Elrond DAO.

    The modules in the homepage can be tailored by the admin. Think of them as widgets, allowing admins to decide the homepage content. For instance, we have the "Announcements" and "Activity Log" modules. The former shows important messages from DAOs, the latter logs recent DAO activities.

    On the right, customized modules like “My Tasks” display the tasks a contributor has committed to, “Open Bounties” highlight ongoing bounties, and some metrics modules that display "400 Contributors" and "20K Followers".

    {{img:/img/Elrond/DAOHome.png}}



    <h3>5. Tasks</h3>
    When a contributor clicks on Tasks in the DAO menu, it would lead them to the tasks page where a contributor can discover and manage tasks. At the top of the page, there are several buttons. Contributors can discover new tasks, go to their committed tasks, view saved tasks, or even create new tasks if they’ve reached a certain role within the DAO. Then, the body of the Task page is mainly populated with lists of tasks.

    Selecting "Tasks" from the DAO menu brings contributors to a task management page. Here, they can discover, manage, view, or even create tasks, given the right DAO role. The body of the page mainly showcases task lists. There are two different types of tasks, indicated by two different symbols:
    <ul>
    <li># : Signifies bounties, which are small tasks that rewards contributors with monetary rewards upon completion. Bounties typically have 1 or 2 responsible contributors.</li>
    <li>~ : Represents projects, which encompass numerous bounties and typically demand more time and collaboration between many contributors.</li>
    </ul>

    It's important to note the monetary and reputation rewards linked to each task, determined by the task managers. As contributors complete tasks, they can gain reputation tokens and ascend in roles, gain more privileges, get better pay, and have more responsibilities.

    {{img:/img/Elrond/TaskHome.png}}

    Below is the page contributors go to when they click on a project. You'll find details, bounties, responsible contributors, and a description of the project. Proposals for new tasks that complement the project's objectives can be submitted for managerial (a higher role) approval.

    {{img:/img/Elrond/ProjectHome.png}}

    The top right corner features three buttons. The first is button is a kanban view where contributors can holistically view the project’s progression. Below is the design of the kanban page.

    {{img:/img/Elrond/Kanban.png}}

    The second button is the proposal button where contributors can view all proposals in the project. There are stages to the voting process in a DAO. It all starts with a contributor creating a proposal and discussing the project’s improvement. If the draft receives an amount of support that passes the pre-set quorum, it goes through the voting process. The stages are represented using colors: 
    <ul>
    <li>Gray: Proposal is in the draft phase and hasn’t reached the required quorum</li>
    <li>Green: Ongoing voting process</li>
    <li>Red: Voting process has ended</li>
    </ul>

    If a contributor clicks on a proposal, it would navigate to a page with proposal details, discussion and voting progress.

    {{img:/img/Elrond/ProjectProposal.png}}

    The third button is the discussion button where contributors can engage in project’s discussion.

    {{img:/img/Elrond/ProjectDiscussions.png}}

    Extra: The example above is the flow of a project. Below is the design of a bounty’s page. The main difference is instead of a kanban button, bounties have a submission page.

    {{img:/img/Elrond/BountyHome.png}}



    <h3>6. Voting</h3>
    The Voting Booth maintains a design consistency with the proposal page seen earlier. The buttons on the top of the page allow contributors to discover proposals, view saved proposals, see the past proposals they voted in, and create new proposals, while the main section displays all proposals in the DAO.

    {{img:/img/Elrond/VotingHome.png}}



    <h3>7. Discussions</h3>
    Just like the Voting Booth page, the Discussions page bears a design resemblance to the earlier project page discussion segment.

    {{img:/img/Elrond/DiscussionHome.png}}



    <h3>Contributors</h3>
    Here, contributors can discover other contributors within the DAO. We display the roles and reputation tokens of every contributor. On the right, admins can add modules, such as a leaderboard spotlighting top contributors.

    In my article, "Reputable Governance", I stress the importance of roles in DAOs. These roles facilitate efficient DAO operations by defining unique responsibilities and abilities. For instance, only the Grand Admiral and Senior Lieutenant roles have the ability to manage projects. Similarly, only the Grand Admiral can create new roles. 

    {{img:/img/Elrond/ContributorsHome.png}}



    <h3>8. Treasury</h3>
    Treasury is one of the more important pages in Elrond. This page is a great example of use case of integration. Elrond DAO use the Gnosis Safe integration, managing a safe multisig account right from Elrond.

    The Treasury's main page splits into two sections: the left lists all the treasuries in the DAO. There is the main treasury and pod-specific treasuries that are managed independently by the pod members, normally the higher ranked roles. The right side displays the details of the chosen treasury, listing its balance, assets, and recent transactions.

    {{img:/img/Elrond/TreasuryHome.png}}

    Below is the proposal page of the Main Treasury, which consistently mirrors proposal pages elsewhere.

    {{img:/img/Elrond/TreasuryProposals.png}}

    There is also a Discussions page, facilitating any chats regarding the selected treasury

    {{img:/img/Elrond/TreasuryDiscussion.png}}



    <h3>Conclusion</h3>
    Since this is a big project, the pages and functionalities showcased above merely scratch the surface. For a deeper dive into Elrond, feel free to reach out via email.



    `,
  },
  {
    id: 101,
    title: "Inner Geist & Non-Obviousness",
    date: "May 2023",
    section: "others",
    content: `
    Below are the slides for a presentation I gave in Cornell in Spring 2023.

    {{img:/img/Slide 1.png}}

    {{img:/img/Slide 2.png}}

    {{img:/img/Slide 3.png}}

    {{img:/img/Slide 4.png}}

    {{img:/img/Slide 5.png}}
    
    {{img:/img/Slide 6.png}}
    
    {{img:/img/Slide 7.png}}
    
    {{img:/img/Slide 8.png}}
    
    {{img:/img/Slide 9.png}}
    
    {{img:/img/Slide 10.png}}
    
    {{img:/img/Slide 11.png}}
    
    {{img:/img/Slide 12.png}}
    
    {{img:/img/Slide 13.png}}
    
    {{img:/img/Slide 14.png}}
    
    {{img:/img/Slide 15.png}}
    
    

    `,
  },
  {
    id: 21,
    title: "On the Endgame",
    date: "August 2023",
    section: "blogpost",
    content: `
    In the current web3 landscape, the governance of decentralized protocols is largely homogeneous. The standard framework typically follows the process outlined below:

    {{img:/img/DAODMP.png}}

    A proposal is usually posted on the well-known but outdated Discourse . Often called "{ProtocolName} Improvement Proposals," if these proposals garner enough support, they are moved to Snapshot for the protocol's token holders to vote on. If the Snapshot proposal passes, changes are either automatically implemented via a smart contract or done manually.

    This barebone governance often results in internal conflicts and inefficient decision-making process due to its non-existent structure. At the end of the day, governance is all about managing people and individuals need clear structure in place.

    Decentralized protocols need to understand that success in any enterprise mainly stems from team synergy rather than product quality alone. The inefficiency of decentralized governance is the industry's key challenge. Addressing this can propel Web3 applications to new heights, fulfilling crypto's ethos of self-sovereignty.

    MakerDAO, creators of DAI, have been pioneers in DAO innovations. They've been experimenting with governance since its inception in 2018 and have implemented creative mechanisms  like using Core Units, Unique Voting Mechanisms, Governance Cycles, and Committees.

    Last year, Maker's founder Rune Christensen proposed a transformative change to Maker's governance, dubbed "The Endgame," aimed at transitioning governance to a self-sufficient system. This article focuses on MakerDAO's governance aspect, exploring its potential to serve as a universal DAO governance framework.


    <h3>1. Atlas Implementation</h3>
    Rune's vision is to create a self-sustaining DAO that can function without dependence on specific stakeholders. To achieve this, he introduced Atlas, a document that lists all DAO rules and culture, acting like a constitution that  all operations inside the organization must follow.

    Additionally, Rune introduced the concept of Scopes for the further division of work. Each operation within MakerDAO must align with the pre-determined Scopes, like Governance, Support, Protocol, Stability, and Accessibility in MakerDAO's case. These Scopes have their own specific set of rules - called Scope Artifacts - that all operations must abide by. Both Atlas and the Scope Artifacts are maintained and protected by a group of people called the Alignment Conservers.

    
    <h3>2. Alignment Conservers</h3>
    Alignment Conservers (AC) are individuals who pledge to uphold the Atlas and oppose any entities threatening it. These contributors are rewarded for their active participation through discussion, proposal creation, and voting. There are two types of Alignment Conservers: Aligned Voter Committees (AVC) and Aligned Delegates (AD).

    <strong> a. Aligned Voter Committee (AVC) </strong>
    The primary objectives of an AVC include maintaining the Scope Artifacts and ensuring that Aligned Delegates execute according to the AVC's vision. Certain requirements must be met to become an AVC, such as creating an Aligned Governance Strategy document and holding two AVC subcommittee meetings for each scope. AVCs can adopt one of two strategies: Hawkish focuses on short-term income and asset accumulation, while Dovish concentrates on long-term growth and innovation. It is crucial to have a balance of both types of AVCs inside a DAO.

    <strong> b. Aligned Delegates (AD) </strong>
    Aligned Delegates have the following responsibilities:
    <ul>
    <li>Actively vote and provide research material, and execute proposals</li>
    <li>Help design and improve Scope Improvement Articles</li>
    <li>Participate in weekly/monthly meetings</li>
    <li>Research relevant happenings in the ecosystem</li>
    </ul>


    <h3>3. Sub-DAO</h3>
    One significant improvement MakerDAO has implemented is the introduction of Sub-DAOs. These function as semi-independent groups with their own governance tokens, processes, workforce, and treasury. These Sub-DAOs are experimental by design, but they offer significant upside potential for contributors to grow the protocol and get rewarded by contributing early. Sub-DAOs will also fall under DAO scopes and adhere to the Scope's Artifacts and the Atlas.


    <h3>4. Farming DAO Tokens</h3>
    With multiple Sub-DAOs having their own governance tokens, Rune introduced the Sagittarius Engine as the new token farming strategy. To earn governance tokens from these Sub-DAOs, individuals must stake their MKR tokens. The staking mechanism doesn't include a time restriction, but unstaking requires forfeiting 50% of the staked tokens, thus incentivizing contributors' long-term participation in the protocol. Contributors can then be rewarded either in governance tokens or DAI.


    <h2>Thoughts</h2>
    The Endgame proposal offers a structured approach to governance through its Atlas, Scopes, and Sub-DAOs. These components - Constitution, Roles, and Independent Functions - are vital to any DAO's success. Now, the objective should be to generalize this methodology, add some more helpful strategies, and develop a framework for any DAOs to implement. 
    
    To develop a robust governance framework, I suggest considering the following:

    <h3>1. Unite The Systems</h3>
    The high degree of fragmentation in on-chain governance platforms complicates participation and contributes to voter apathy. Right now contributors need to juggle between multiple platforms to be involved in DAO operations. For example: voting is done in Snapshot, discussion is done in Discourse, chat is in Discord, bounties in Dework, accounting in Utopia, and treasury in Safe.

    Fragmentation also leads to inefficiencies, conflicts, and contributor burnouts. To streamline governance, we should aim for vertical integration by creating an all-in-one DAO management platform. This could be achieved either by building clones from scratch or creating an interface on top of existing platforms.

    There is no best approach here, but something to consider is the tradeoff between versitility and performance. The optimal solution is striking the balance between the two. To understand what I meant by the interface approach, you can look at my design of an all-in-one DAO platform that my friend and I made two years ago called Elrond.


    <h3>2. Dynamic Roles and Responsibilities</h3>
    In any organization, roles with clear power hierarchies are essential for maximum efficiency. This proven approach shouldn't be overlooked in the decentralized organization realm. MakerDAO's Endgame articulates this effectively with its Aligned Voter Committees and Aligned Delegates roles. The responsibilities tied to these roles should be explicitly outlined, either in a formal document or as part of Scope Artifacts.

    Another point to consider is the selection process for these roles. Should there be open criteria that anyone can meet, or should community members elect contributors? If the latter is preferred, an impeachment process must also be considered and detailed in the Scope Artifacts.

    A DAO featuring diverse roles and a clear hierarchy will be dynamic, each role having its own unique operational processes, schedules, and compensation schemes.


    <h3>3. Adoption of AP Actions</h3>
    Roles in a DAO should inherently come with complete delegation of responsibilities, especially when these roles are community-elected. It's impractical for individuals in roles to seek community approval for every decision. This would only slow down decision-making processes or signal low level of trust within the DAO- both detrimental to achieving efficient decentralized organizations.

    Instead, individuals in designated roles should have the ability to execute tasks quickly, striking a balance between decision making velocity and community involvement. AP Actions, introduced in my previous article <a href="https://medium.com/@pikanto/on-decentralized-governance-degov-9539b50777f4"> Reputable Governance</a>, can be used to strike balance between decision making velocity and community's consensus. Decisions under AP Actions automatically pass unless a specific quorum of opposing votes is reached, specified in the Atlas or Scope Artifacts.


    <h3>4. Dynamic Voting Weights</h3>
    As outlined in <a href = "https://medium.com/@pikanto/on-decentralized-governance-degov-9539b50777f4">Reputable Governance</a>, voting structures should be flexible to support the diverse functional groups a DAO has. Generally, there are three ways to weigh a vote: One-Token-One-Vote (OTOV), One-Person-One-Vote (OPOV), and Custom. For simplicity, we'll focus on the first two.

    OTOV is the most popular structure in Web3, yet it is also the most problematic; it essentially allows the richest individuals or those holding the most tokens to run the protocol at will. Additionally, given that governance tokens are publicly traded, the notion of "buying votes" undermines the ethos of decentralization. To counter this, I propose using Reputation Tokens, earned through contributions to the DAO, to give more voice to active participants. Different tasks or actions rewards different amount of Reputation Tokens, and these will be specified in the Scope Artifacts by high-role members.

    OPOV, akin to government elections, is currently underutilized in DAO governance, primarily because many DAOs lack the use of roles. However, this mechanism has been proven to be the most sybil resistant and reliable compared to others.

    Since voting structures are situational, modularity is crucial. DAOs should have the ability to custom-create and mix-and-match different voting weight types based on where the mechanism is implemented. For instance:

    In a Product Development SubDAO, all decisions should follow the below voting distribution:
    <ul>
    <li>60% SubDAO Reputation Token Holders (OTOV)</li>
    <li>20% SubDAO Governance Token Holders (OTOV)</li>
    <li>10% SubDAO Contributor Role (OPOV)</li>
    <li>10% SubDAO Manager Role (OPOV)</li>
    </ul>

    Task categories can also have different voting distributions. For example, in the Main DAO, decisions that fall under "Treasury Management" category, will adhere the following voting distribution:
    <ul>
    <li>70% DAO Governance Token Holders (OTOV)</li>
    <li>10% DAO Finance Managers (OPOV)</li>
    <li>10% Protocol Founders (OPOV)</li>
    <li>10% Protocol Early Backers (OPOV)</li>
    </ul>

    The aim here is to design a robust governance framework that supports all types of situations or use cases within a modular DAO. One of the ways is including the use of Reputation Tokens to shift the power dynamics away from whales and towards those genuinely invested and active in daily protocol operations.




    `,
  },
  // {
  //   id: 23,
  //   title: "Dynamic Smart Contract",
  //   date: "September 2023",
  //   section: "blogpost",
  //   content: `
  //   coming soon...
  //   please read the other posts for now :)
  //   `,
  // },
  {
    id: 24,
    title: "Usage-Gated Tokens",
    date: "September 2023",
    section: "blogpost",
    content: `
    {{img:/img/XP_Bliss.jpg;style:width:100%}}
    
    Among the myriad value propositions of cryptocurrency, one particularly fascinating aspect is its potential to forge a more equitable society. Publicly issued NFTs, sanctioned by governments, hold tremendous potential. Public documents such as land registries, birth certificates, and citizenship records can be tokenized to ensure their authenticity, tamper-resistance, and security. Digitizing these documents on the blockchain is preferable to centralized digital issuance because it ensures that they are owned directly by the individuals rather than intermediaries.

    Tokenizing public documents paves the way for a plethora of opportunities, as a wallet address can now hold a robust reputation system. I see a future where Web3 becomes mainstream, marked by a standard where everyone has a wallet address that’s officially tied to their real-life identity. While it will still be feasible to have multiple wallets or pseudonymous accounts separate from one’s true identity, each person would have a primary, KYC-compliant wallet. 

    This presupposes a world where crypto wallets are universally adopted, crypto payments are widely accepted, exchanges between crypto and fiat are seamless, and on-chain identity usage is the norm. Achieving this requires concerted efforts from diverse sectors and we are far from this reality. Nevertheless, when this vision comes to fruition, society will reap the benefits of a more transparent financial infrastructure and a self-sovereign identity system.

    The evolution of social networks offers a relevant analogy. The early players in web2 had an approach of creating a realm where one could establish pseudonymous identities. Yet, platforms founded on real identities ultimately prevailed because they could engender trust, reputation, and identity—key ingredients for establishing trust. This is why platforms like SocialNet faltered while Facebook thrived. I posit that Web3 will follow a similar trajectory.

    So, what upsides could tokenization bring into the real world? Let’s consider several examples out of the many possibilities that on-chain reputation systems could enable:
    <ul>
    <li>Verification: The blockchain’s immutability could facilitate verification processes, as proof of authenticity. For instance, before engaging in real estate transactions, an individual’s citizenship NFT could serve as proof of eligibility for home ownership. In regions where land ownership disputes are rampant due to poor record-keeping, the immutable nature of blockchain will be the solution.</li>
    <li>Universal Basic Income (UBI): Governments could streamline UBI distributions by using smart contracts to airdrop funds to citizens' wallets containing the citizenship NFT. The transparency of the fund distribution and instant settlement period will be a 10x improvement to the current system. Moreover, these NFTs could reflect residency, employment location, or profession, enabling hyper targeted UBI distributions tailored to specific needs—like providing additional support to residents of areas with lower living standards or swiftly deploying aid to disaster-struck regions.</li>
    <li>Voting: Previous attempts to create civic engagement platforms have struggled with issues like susceptibility to Sybil attacks and a central authority that controls all of the information, all of which eroded the public’s trust. A decentralized system, however, could offer the transparency and trust necessary to foster widespread participation. Here, government-issued citizenship NFTs could ensure one person, one vote—applying to elections, tax allocation decisions, and public good funding. </li>
    <li>Civic Engagement Tokens: As a more experimental approach, active participation in civic duties could be incentivized with tokens that acknowledge and reward engagement, potentially nurturing a more proactive citizenry. </li>
    </ul>
    
    This article would primarily talk about the potential web3 in reimagining public goods funding. The impact of this paradigm shift extends beyond governmental applications, but also has the potential to revolutionize the operational models of non-governmental organizations (NGO) or charities.


    <h3>The Good and The Bad of Charitable Organizations</h3>
    Non-governmental organizations (NGOs) and charities excel in execution. They employ high skilled and coordinated individuals, and have deep-rooted connections within communities, allowing them to pull the strings required to maximize impact through charitable projects. Their social ties, especially in lower-income communities, are invaluable.

    However, these organizations often face criticism for allocating a disproportionate amount of funding to administrative costs, leaving less for the intended causes. Furthermore, there is a perception that they may not adequately represent the needs of the communities they serve, with funds not always addressing the most pressing issues.

    Before delving into how Web3 can address these challenges, let's examine the current state of public good funding (PGF) in the Web3 ecosystem.


    <h3>The Current Public Good Funding (PGF) in web3</h3>
    In the blog post <a href="https://otherinter.net/research/positive-sum-worlds/">Positive-Sum Worlds</a> public goods are defined as "anything that is both non-excludable and non-rivalrous" meaning access cannot be barred, and one person’s use doesn't detract from another's. 

    Web3's open-source, permissionless, and community-governed nature is fertile ground for innovative PGF approaches. For instance, Gitcoin employs quadratic funding to support Ethereum ecosystem projects. To understand quadratic funding in detail, check out this website: https://www.wtfisqf.com/. The idea hinges on using the collective interest of individual contributors as a metric for project significance, amplified by a matching fund calculated quadratically.

    Optimism uses retroactive public goods funding, rewarding projects post-creation based on their demonstrated benefit to the community, rather than providing funding upfront. The idea is to reward projects that have proven to be beneficial to the public or a community, encouraging more of such work in the future. 

    These PGF experiments in Web3 are aimed to address the problems faced by the centralized counterparties. This raises the question of whether such decentralized models can effectively supplant the traditional methods, offering a more equitable and efficient approach to public good funding in the real world.


    <h3>Real-Life PGF with Web3</h3>
    Charitable organizations act as intermediaries. One of the key aspirations of web3 is to create efficient systems, at times by eliminating these middleman. That said, understanding why intermediaries exist and the value they offer, is very important especially in reconstructing the system. In this context, we must recognize the crucial organizational ability that the incumbents offer.

    There are three core components of any public goods funding framework: the funders, the distribution mechanism, and the recipients. Funding can originate from a variety of sources, primarily falling into two categories: individuals and corporations. When we reimagine real-life public goods funding within the Web3 context, the first step is to empower the communities in need to identify and vocalize their specific issues and funding necessities. While some issues may be resolved with a direct money transfers, others may necessitate more intricate coordination, such as the construction of key infrastructures. In these instances, the community would need to engage third parties and integrate them into the funding plan. Importantly, the distribution of funds would be transparently recorded on the blockchain.

    There are two ways this could play out: 

    <strong>Bottom-up Approach</strong>
    Reminiscent of crowdfunding platforms like GoFundMe, this method allows communities to create campaigns, setting fundraising goals and detailing the planned fund distribution to individuals or third parties. Funders around the world can then fund these campaigns.

    The decentralized version of this model is inherently better than platforms like GoFundMe, as it removes the middleman, allowing for instant and more efficient fund transfer. It also provides customizability in fund distribution, such as releasing funds in batches based on monitored progress through smart contracts. Moreover, the transparency of the money flow allows donors to establish trust and see exactly where their contributions are going.


    <strong>Top-down Approach (DAO)</strong>
    This method presents a more innovative and efficient model of capital distribution. Instead of numerous many funding multiple campaigns, funders pool their funds into a shared treasury and collectively decide on the allocation of funds. Communities in need can then submit fundraising proposals, detailing the requested amounts, distribution plans, involved parties, etc.

    Ideally, this model would feature periodic funding rounds, enabling funders to vote on where and how they would prefer to allocate the treasury fund. The approval process includes several review stages, fostering discussions among funders and potential recipients about the plan, distribution structure, third party evaluation, and more. The DAO can hire contributors whose jobs are to review proposals and monitor the funding process. Following the review stages, DAOs would select several proposals for funding in the current round, distribute the funds, and then begin another round.

    When this model is implemented, there would likely be numerous funding DAOs of varying sizes and focuses, such as health, education, or food. The transparent and open nature of this platform fosters trust and encourages global participation, increasing the total capital directed towards underserved communities and thus contributing to a more equitable society.


    <h3>Usage-Gated Tokens (UGTs)</h3>
    After being able to donate to a specific population with government-issued NFTs, it's essential to ensure that the donations are not misused for unintended items like drugs or alcohol. Ideally, donors want their contributions to be used for necessities such as food, education, or healthcare. Is there a mechanism to restrict the usage of donated funds? Traditionally, no. But what if we didn't rely on fiat currency?

    Introducing "Usage Gated Tokens" (UGTs). Think of them as purpose-specific digital vouchers, each encoded with a defined usage that is strictly enforced. These tokens stand out for three key characteristics: they are universal, which means anyone can own and use them anywhere; tradable, which allows them to be exchanged in a free market; and permissionless, ensuring that no single entity has the power to restrict it. Despite these features, the innovation of UGTs lies in their utility restriction—their use is limited strictly to the purpose for which they were created for.

    An example of this concept is education-tokens, that can be donated to underserved communities, ensuring that the donation directly impacts their education-related needs. Instead of simply donating in fiat currencies, which can be used for any purpose, the specificity of the education tokens ensures they are spent for school tuition, supplies, and other related expenditures. Then the redemption of education-tokens will be restricted to the parties that owned “educational party” NFT like schools, tutoring centers, etc. These entities would have access to a smart contract, enabling them to convert education tokens into stablecoin or fiat currency

    However, this concept does come with concerns. For instance, if entities with the authority to redeem education tokens for fiat or stablecoin strike deals with token holders, they could convert and return the funds, circumventing the usage restrictions. This scenario undermines the intent of usage-targeted tokens and highlights a challenge that requires a solution to preserve the integrity of UGTs.
    `,
  },
  /*{
    id: 26,
    title: "Big Brother Moment",
    date: "October 2024",
    section: "blogpost",
    gated: true,
    content: `
    coming soon...
    you can read the other posts for now :)
    `,
  },*/
  {
    id: 27,
    title: "Dub: Make the Trenches Fun Again",
    date: "April - October 2024",
    section: "designs",
    gated: true,
    content: `
    {{img:/img/dub-banner.png}}
    
    Desc: Make the trenches fun again!
    Start/End Date: April - October 2024
    Role: Founding Designer
    Team: jdubz, trenchie, anz
    Projects: Mobile PWA, Desktop, Illustrations, Logo, Memes

    <hr>
    <h3> stop getting rugged on memes. bet early on cults and win big! </h3>

    Dub solves two main issues in the memecoin space: it’s too hard to separate gems from the trash and barely anyone works for their bags. Dub allows users to fair-launch memecoins via a bonding curve (like PumpFun) but with social rewards. At inception, coins will have a portion of supply allocated to social rewards. These social rewards get distributed over time and airdropped to the community if market cap milestones are reached.

    Dub solves for the discovery problem by surfacing up-and-coming memecoins with a focus on real users backing it by integrating with Twitter’s social graph. Dub offers trading for: 1) Dub coins on the bonding curve; 2) graduated Dub coins; 3) graduated PumpFun coins.

    I was responsible for designing the mobile and desktop apps from the ground up, creating marketing assets, driving QA process, and frontend dev. We collaborated with a third-party illustrator on the logo and memes, and worked with an agency to produce the app’s intro video.

    Below is a sneak peak of the mobile and desktop design. Contact me for more details. Follow our <a href="https://x.com/dubdotsocial/" target="_blank">Twitter</a> and join the <a href="http://crypto.social/" target="_blank">waitlist</a>. 
    
    <h3> Mobile </h3>
    {{img:/img/dub-mobile.png}}

    <h3> Desktop </h3>
    {{img:/img/dub-desktop-1.png}}
    {{img:/img/dub-desktop-2.png}}
    {{img:/img/dub-desktop-3.png}}

    <h3> Memes </h3>
    {{img:/img/dub-memes.png}}
    `,
  },
  {
    id: 28,
    title: "Orca V2: It Just Works",
    date: "October 2023 - Present",
    section: "designs",
    gated: true,
    content: `
    {{img:/img/orca-banner.png}}
    
    Desc: Make Orca great again!
    Start/End Date: October 2023 - Present
    Role: Product Design
    Team: Tyler, Zach, Will, Tmoc, Meeep  
    Projects (21): Reposition, Liquidity Terminal, Design System, Trade, Create Position, Pools, Portfolio, Position Details, Create Pool, Create Token, Blinks, User Persona, Rebrand, Pool Details, Token Extensions, Settings, Nav, Customer Support, Eclipse, Stats API, Marketing.

    <hr>

    In late 2023, Orca was losing market share to Raydium and the app was extremely slow, with frequent transaction failures requiring up to 20 retries. User complaints were mounting, so we initiated a complete app overhaul to improve performance and user experience with Orca V2.

    Tyler (the other designer) and I led the design of this major initiative. In addition to design, we took on product management responsibilities, managed the QA process, and I did some frontend development with feature work and bug fixes. We began by developing a design system to establish strong foundations, then focused on enhancing the performance and aesthetics of existing features while introducing new ones, such as pool details, Eclipse, and PnL. We also collaborated with the newly formed marketing team to create necessary assets and are currently assisting with a rebranding effort (new colors, illustrations, vibe) in partnership with a third-party agency.

    Check out the old <a href="https://v1.orca.so/" target="_blank">Orca V1</a> and the current <a href="https://orca.so/" target="_blank">Orca V2</a>. Some of the projects mentioned above are still private, so contact me if you want to learn more!

    <h3> Home </h3>
    {{img:/img/orca_trade.png}}

    <h3> Pools + Portfolio </h3>
    {{img:/img/orca_pp_1.png}}
    {{img:/img/orca_pp_2.png}}

    <h3> Pool Details </h3>
    {{img:/img/orca_pd_1.png}}
    {{img:/img/orca_pd_2.png}}
    `,
  },
  {
    id: 100,
    title: "Revy: Cornell's Carpool Platform",
    date: "November 2023",
    section: "others",
    externalUrl:
      "https://cornellsun.com/2023/11/27/cornell-students-launch-revy-carpooling-platform-for-intercity-travel/",
  },
];

export default blogPosts;
