import React, { useState, useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import Header from "../components/Header";
import BlogPostList from "../components/BlogPostList";
import SelectedBlogPost from "../components/SelectedBlogPost";
import blogPosts from "../data/blogPosts";
import SelectedBlogPostMobile from "../components/SelectedBlogPostMobile";
import "../styles/selectedBlogPost.css";

const HomePage = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);
  const navigate = useNavigate();

  // Create a new sorted array of blog posts
  const sortedBlogPosts = [...blogPosts].sort((a, b) => b.id - a.id);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const handlePostClick = (postId) => {
    const post = sortedBlogPosts.find((post) => post.id === postId);

    if (post.externalUrl) {
      // Open the external URL in a new tab
      window.open(post.externalUrl, "_blank");
    } else if (isDesktop) {
      setSelectedPost(post);
    } else {
      navigate(`/post/${postId}`);
    }
  };

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    minHeight: "100vh",
  };

  const contentContainerStyle = {
    maxWidth: "800px",
  };

  const leftBoxStyle = {
    display: "flex",
    // border: isDesktop ? "solid 0.8px #E4E4E4" : "none",
    flexDirection: "column",
    justifyContent: "start",
    borderRadius: "25px",
    padding: isDesktop ? "28px" : "4px",
    paddingTop: isDesktop ? "10px" : "8px",
    minWidth: "600px",
    margin: isDesktop ? "16px" : "16px",
    marginRight: isDesktop ? "0px" : "20px",
  };

  return (
    <div style={containerStyle}>
      <div style={leftBoxStyle}>
        <Header />
        <BlogPostList posts={sortedBlogPosts} onPostClick={handlePostClick} />
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <div
              style={{
                backgroundColor: selectedPost ? "#F5F5F3" : "#FFFFFF",
                minWidth: "300px",
                flexGrow: 2,
                flexBasis: "0",
                padding: selectedPost ? "2.5rem" : "0",
              }}
            >
              <div style={contentContainerStyle}>
                <SelectedBlogPost post={selectedPost} />
              </div>
            </div>
          }
        />
        <Route path="/post/:id" element={<SelectedBlogPostMobile />} />
      </Routes>
    </div>
  );
};

export default HomePage;
