import React, { useState } from "react";
import "../styles/selectedBlogPost.css";

const PasswordPrompt = ({ onCorrectPassword }) => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (password === process.env.REACT_APP_PROTECTED_PASSWORD) {
      onCorrectPassword();
    } else {
      setErrorMessage("Incorrect password. Please try again.");
    }
  };

  return (
    <div className="blog-container">
      <p className="blog-title">
        {" "}
        <img
          src="../img/duckError.png"
          alt=""
          style={{ width: "80px", paddingRight: "15px" }}
        />
        oops...
      </p>
      <p className="blog-body">
        this is a gated post. Please enter the password below.
      </p>
      <input
        type="password"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSubmit}>Submit</button>
      {errorMessage && <p>{errorMessage}</p>}
      <br />
      <p className="blog-body">
        <i>
          (the password is on my resume or contact me if you don't have
          it)
        </i>
      </p>
    </div>
  );
};

export default PasswordPrompt;
