import React, { useState } from "react";
import BlogPost from "./BlogPost";

import "../styles/blogPostList.css";

const BlogPostList = ({ posts, onPostClick }) => {
  const [clickedPostId, setClickedPostId] = useState(null); // <-- New state for clicked post

  const handlePostClick = (postId) => {
    // <-- New handler function
    setClickedPostId(postId);
    onPostClick(postId);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const blogPosts = posts.filter((post) => post.section === "blogpost");
  const paintings = posts.filter((post) => post.section === "paintings");
  const aboutMe = posts.filter((post) => post.section === "aboutMe");
  const others = posts.filter((post) => post.section === "others");
  const designs = posts.filter((post) => post.section === "designs");

  return (
    <div>
      <ul>
        {aboutMe.map((post) => (
          <li
            key={post.id}
            className={`post ${
              post.id === clickedPostId ? "clicked-post" : ""
            }`}
          >
            <BlogPost post={post} onClick={() => handlePostClick(post.id)} />
          </li>
        ))}
      </ul>
      <p></p>
      <p className="category-title">Designs</p>
      <ul>
        {designs.map((post) => (
          <li
            key={post.id}
            className={`post ${
              post.id === clickedPostId ? "clicked-post" : ""
            }`}
          >
            <BlogPost post={post} onClick={() => handlePostClick(post.id)} />
          </li>
        ))}
      </ul>
      <p> </p>
      <p className="category-title">Writings</p>
      <ul>
        {blogPosts.map((post) => (
          <li
            key={post.id}
            className={`post ${
              post.id === clickedPostId ? "clicked-post" : ""
            }`}
          >
            <BlogPost post={post} onClick={() => handlePostClick(post.id)} />
          </li>
        ))}
      </ul>
      <p> </p>
      <p className="category-title">Paintings</p>
      <ul>
        {paintings.map((post) => (
          <li
            key={post.id}
            className={`post ${
              post.id === clickedPostId ? "clicked-post" : ""
            }`}
          >
            <BlogPost post={post} onClick={() => handlePostClick(post.id)} />
          </li>
        ))}
      </ul>
      <p> </p>
      <p className="category-title">Others</p>
      <ul>
        {others.map((post) => (
          <li
            key={post.id}
            className={`post ${
              post.id === clickedPostId ? "clicked-post" : ""
            }`}
          >
            <BlogPost post={post} onClick={() => handlePostClick(post.id)} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogPostList;
