import React from "react";
import "../styles/imageOverlay.css";

const ImageOverlay = ({imageSrc, onClose}) => {
    return(
        <div className="overlay" onClick={onClose}>
            <img src={imageSrc} alt="Expanded"/>
        </div>
    );
};

export default ImageOverlay;