import React from "react";

const BlogPost = ({ post, onClick }) => {
  const handleClick = () => {
    onClick(post.id);
  };

  return (
    <p className="post" onClick={handleClick} style={{ cursor: "pointer" }}>
      <div className="post-content">{post.title}</div>
    </p>
  );
};

export default BlogPost;
