import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import blogPosts from "../data/blogPosts";
import "../styles/selectedBlogPost.css";
import PasswordPrompt from "./PasswordPrompt.js";

const SelectedBlogPostMobile = () => {
  let { id } = useParams();
  id = Number(id);
  const [post, setPost] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const foundPost = blogPosts.find((post) => post.id === Number(id));
    setPost(foundPost);
  }, [id]); // Re-fetch when 'id' changes

  if (!post) {
    return <div>Loading...</div>;
  }

  if (post.gated === true && !hasAccess) {
    return <PasswordPrompt onCorrectPassword={() => setHasAccess(true)} />;
  }

  const content = post.content.split("\n").map((item, index) => {
    const trimmedItem = item.trim();
    const imgRegex = /^{{img:(.+?)(?:;style:(.+))?}}$/;
    const imgMatch = trimmedItem.match(imgRegex);

    if (imgMatch) {
      const imgSrc = imgMatch[1];
      const customStyle = imgMatch[2]
        ? imgMatch[2].split(";").reduce((styleObj, style) => {
            const [property, value] = style.split(":");
            return { ...styleObj, [property.trim()]: value.trim() };
          }, {})
        : {};

      const imageStyle = {
        maxWidth: "100%",
        ...customStyle,
      };

      return <img key={index} src={imgSrc} alt="" style={imageStyle} />;
    }

    return (
      <p
        className={`blog-body ${trimmedItem === "" ? "empty-paragraph" : ""}`}
        key={index}
        dangerouslySetInnerHTML={{ __html: trimmedItem }}
      ></p>
    );
  });

  return (
    <div className="blog-container">
      <p className="blog-title">{post.title}</p>
      <p className="blog-date">{post.date}</p>
      {content}
    </div>
  );
};

export default SelectedBlogPostMobile;
