import React from "react";
import "../styles/header.css";

const Header = () => {
  return (
    <header>
      <div className="header-container">
        <p className="header-title-frame">
          <a href="/" className="title-link">
            Prawira <span className="pikanto">Pikanto</span>
          </a>
        </p>
        {/* <div className="ducks-frame">
          <div className="ducks">
            {[...Array(8)].map((_, i) => (
              <React.Fragment key={i}>
                <img src="img/duck1.png" alt="Duck 1" className="duck" />
                <img src="img/duck2.png" alt="Duck 2" className="duck" />
                <img src="img/duck3.png" alt="Duck 3" className="duck" />
                <img src="img/duck4.png" alt="Duck 4" className="duck" />
              </React.Fragment>
            ))}
            {[...Array(8)].map((_, i) => (
              <React.Fragment key={i + 4}>
                {" "}
                <img src="img/duck1.png" alt="Duck 1" className="duck" />
                <img src="img/duck2.png" alt="Duck 2" className="duck" />
                <img src="img/duck3.png" alt="Duck 3" className="duck" />
                <img src="img/duck4.png" alt="Duck 4" className="duck" />
              </React.Fragment>
            ))}
          </div> 
        </div>*/}
      </div>
      {/* <p class="header--subtitle">
        Research at{" "}
        <a href="https://twitter.com/CUBlockchain">Cornell Blockchain</a>
      </p> */}
      {/* <hr /> */}
    </header>
  );
};

export default Header;
